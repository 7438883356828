
import Vue from "vue";
import { adminServiceApi } from "@/utils/services";
import { Dashboard } from "@/utils/types";
import { StringToJson } from "@/utils/methods";

export default Vue.extend({
  name: "Dashboard",

  components: {
    OnlineUsers: () => import("@/components/occtl/OnlineUsers.vue"),
    Iroutes: () => import("@/components/occtl/Iroutes.vue"),
  },

  data(): {
    serverStats: Dashboard;
    tabs: Array<object>;
    StringToJson: Function;
  } {
    return {
      serverStats: {
        online_users: [],
        show_iroutes: [],
        show_status: "",
      },
      tabs: [
        { id: 1, name: "Show Status", key: "show_status" },
        { id: 2, name: "Online Users", key: "online_users" },
        { id: 3, name: "Show Iroutes", key: "show_iroutes" },
      ],
      StringToJson: StringToJson,
    };
  },

  async mounted() {
    let data = await adminServiceApi.dashboard();
    this.serverStats = data;
    this.serverStats.show_status = `<pre>${this.serverStats.show_status}</pre>`;
  },
});
